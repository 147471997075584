import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { Typography } from '@mui/material';
import { scrollWithOffset } from '../../helpers/scrollWithOffset';

const markdownOptions = {
  forceBlock: true,
  overrides: {
    h1: {
      component: Typography,
      props: {
        variant: 'h4',
        color: 'primary',
        gutterBottom: true,
        align: 'center',
      },
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h5',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h6',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h4: {
      component: Typography,
      props: {
        variant: 'subtitle1',
        color: 'primary',
        gutterBottom: true,
      },
    },
    h5: {
      component: Typography,
      props: {
        variant: 'subtitle2',
        gutterBottom: true,
      },
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        paragraph: true,
      },
    },
    li: {
      component: Typography,
      props: {
        component: 'li',
      },
    },
    td: {
      component: Typography,
      props: {
        component: 'td',
        variant: 'body1',
        style: {
          border: '1px solid #c9c9c9',
          padding: '0 1rem 0 .5rem',
        },
      },
    },
    th: {
      component: Typography,
      props: {
        component: 'th',
        variant: 'body',
        style: {
          border: '1px solid #c9c9c9',
          padding: '0 1rem 0 .5rem',
        },
      },
    },
    table: {
      props: {
        style: { marginBottom: 15 },
      },
    },
    a: {
      props: {
        onClick: (e) => {
          const href = e.target.getAttribute('href');
          if (href.startsWith('#')) {
            e.preventDefault();
            scrollWithOffset(document.getElementById(href.substring(1)));
          }
        },
      },
    },
  },
};

const readFile = async (file) => {
  const response = await fetch(file);
  return response.text();
};

const MarkdownFromFile = ({ file }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    const fetchContent = async () => {
      let fileContent = await readFile(file);

      fileContent = fileContent.replace(
        '{{CookieDeclaration}}',
        '<div id="cookiebot-script"></div>',
      );

      setContent(fileContent);
    };

    fetchContent();
  }, [file]); // Only re-run the effect if `file` changes

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.src = 'https://consent.cookiebot.com/5a4f8371-6fd5-4978-a070-b396f956eb13/cd.js';
    script.async = true;

    const placeholder = document.getElementById('cookiebot-script');
    if (placeholder) {
      placeholder.appendChild(script);
    }

    return () => {
      if (placeholder) placeholder.removeChild(script);
    };
  }, [content]);

  return content ? <Markdown options={markdownOptions}>{content}</Markdown> : null;
};

MarkdownFromFile.propTypes = { file: PropTypes.string.isRequired };

export default MarkdownFromFile;
