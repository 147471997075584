import { Container } from '@mui/material';

import MarkdownFromFile from '../../components/MarkdownFromFile/MarkdownFromFile';
import gtac from '../../markdowns/gtac.md';

const Gtac = () => (
  <Container maxWidth="lg">
    <MarkdownFromFile file={gtac} />
  </Container>
);

export default Gtac;
