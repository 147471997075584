import { Container } from '@mui/material';

import MarkdownFromFile from '../../components/MarkdownFromFile/MarkdownFromFile';
import dataPrivacy from '../../markdowns/dataPrivacy.md';

const DataPrivacy = () => (
  <Container maxWidth="lg">
    <MarkdownFromFile file={dataPrivacy} />
  </Container>
);

export default DataPrivacy;
