import { Container } from '@mui/material';


import MarkdownFromFile from '../../components/MarkdownFromFile/MarkdownFromFile';
import contactSecurity from '../../markdowns/contactSecurity.md';

const ContactSecurity = () => (
  <Container maxWidth="md">
    <MarkdownFromFile file={contactSecurity} />
  </Container>
);

export default ContactSecurity;
