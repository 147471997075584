import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { ScaleModal } from '@telekom/scale-components-react';
import rbacCheck from '../../../rbac/rbacCheck';

import Table from '../../../components/Table';
import CreateUserForm from './CreateUserForm';
import { assignableRoles, userRolesLookup } from '../../../helpers/lookups';
import useApiCall from '../../../hooks/useApiCall';
import Auth from '../../../api/auth';

const Users = () => {
  const { organizationId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [users, setUsers] = useState([]);
  const getUsersCall = useApiCall(Auth.fetchV2Users);
  const createUserCall = useApiCall(Auth.createV2User);

  const userRoles = rbacCheck(userData.roles, '/users:PUT_ROLE_CHECK');

  const canCreateUser = rbacCheck(userData.roles, '/users:POST');

  const getUsers = async () => {
    const [data] = await getUsersCall({ organizationId });
    if (data) setUsers(data);
  };

  const createUser = async (formValues) => {
    const [data, error] = await createUserCall({
      ...formValues,
      organizationId,
    });
    if (error) setCreateModalOpened(false);
    if (data) {
      getUsers();
      setCreateModalOpened(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const mapUsersToRows = users.map((user) => ({
    key: user.uuid,
    onRowClick: () => navigate(`/users/${user.uuid}`),
    data: [
      {
        field: 'email',
        value: user.email,
      },
      {
        field: 'role',
        value: userRolesLookup[user.role],
      },
      {
        field: 'activated',
        value: user.activated ? 'True' : 'False',
      },
    ],
  }));

  return (
    <>
      {createModalOpened && (
        <ScaleModal
          opened
          size="small"
          heading="Create a new user"
          onScale-close={() => setCreateModalOpened(false)}
        >
          { userRoles.length && (
            <CreateUserForm
              roles={assignableRoles(userRoles)}
              onSubmit={createUser}
              onCancel={() => setCreateModalOpened(false)}
            />
          )}
        </ScaleModal>
      )}
      <Table
        header="Users"
        fields={[
          {
            id: 'email',
            title: 'Email',
          },
          {
            id: 'role',
            title: 'Role',
          },
          {
            id: 'activated',
            title: 'Activated',
          },
        ]}
        rows={mapUsersToRows}
        createAction={{
          disabled: !canCreateUser,
          onClick: () => setCreateModalOpened(true),
          tooltip: !canCreateUser ? intl.formatMessage({ id: 'userCreationPermissionErr' }) : '',
        }}
      />
    </>
  );
};

export default Users;
