import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Table from '../../../../../components/Table';
import { userRolesLookup } from '../../../../../helpers/lookups';
import useApiCall from '../../../../../hooks/useApiCall';
import Auth from '../../../../../api/auth';

const Users = ({ organizationId, projectId }) => {
  const { productId } = useParams();
  const userData = useSelector((state) => state.user);

  const activatedProduct = (productIds) => productIds?.includes(productId);

  const [users, setUsers] = useState([]);
  const [project, setProject] = useState({});

  const getUsersCall = useApiCall(Auth.fetchV2Users);
  const getProjectCall = useApiCall(Auth.fetchV2ProjectById);
  const assignProductCall = useApiCall(Auth.assignV2Product);
  const unassignProductCall = useApiCall(Auth.unassignV2Product);

  const getUsers = async () => {
    const [data] = await getUsersCall({ organizationId });
    if (data) setUsers(data);
  };

  const getProject = async () => {
    const [data] = await getProjectCall(projectId);
    if (data) setProject(data);
  };

  const userAssignment = async (user, userId) => {
    if (activatedProduct(user.productIds)) {
      await unassignProductCall({
        userId,
        productId,
      });
    } else {
      await assignProductCall({
        userId,
        productId,
      });
    }
    getUsers();
  };

  useEffect(() => {
    getUsers();
    getProject();
  }, []);

  const fields = [
    { id: 'email', title: 'Email' },
    { id: 'role', title: 'Role' },
    { id: 'status', title: 'Status' },
  ];

  const rows = users.filter((user) => user.activated).map((user) => ({
    key: user._id,
    data: [
      { field: 'email', value: user.email },
      { field: 'role', value: userRolesLookup[user.role] },
      {
        field: 'status',
        type: 'switch',
        props: {
          disabled: userData.roles[0] !== 'ADMIN' && userData._id !== project.technicalLeadId,
          checked: activatedProduct(user.productIds),
          label: activatedProduct(user.productIds) ? 'Active' : 'Inactive',
          'onScale-change': () => userAssignment(user, user._id),
        },
      },
    ],
  }));

  return (
    <Table
      header="Users"
      fields={fields}
      rows={rows}
    />
  );
};

Users.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default Users;
