import { Grid } from '@mui/material';
import { ScaleSidebarNav, ScaleSidebarNavItem } from '@telekom/scale-components-react';

import MarkdownFromFile from '../../../components/MarkdownFromFile/MarkdownFromFile';
import computeSpecification from '../../../markdowns/Compute/specification.md';

const ComputeSpecification = () => (
  <Grid
    container
    justifyContent="space-between"
  >
    <Grid item xs={2}>
      <ScaleSidebarNav>
        <ScaleSidebarNavItem>
          <a href="#Commercial">Commercial Product</a>
        </ScaleSidebarNavItem>
        <ScaleSidebarNavItem>
          <a href="#Evaluation">Evaluation Product</a>
        </ScaleSidebarNavItem>
      </ScaleSidebarNav>
    </Grid>
    <Grid item xs={9}>
      <MarkdownFromFile file={computeSpecification} />
    </Grid>
  </Grid>
);

export default ComputeSpecification;
