import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import rbacCheck from '../../../../../rbac/rbacCheck';

import Overview from './Overview';
import JuiceGroupContacts from '../../../../Reports/JuiceGroupContacts';
import OsProjects from './OsProjects';
import Users from './Users';
import CloudReport from './CloudReport';
import Clusters from './Clusters';

import Provision from '../../../../../api/provision';
import Caas from '../../../../../api/caas';
import Section from '../../../../../components/Section';

import useApiCall from '../../../../../hooks/useApiCall';
import Auth from '../../../../../api/auth';


const ProductDetails = () => {
  const getProductCall = useApiCall(Auth.fetchV2ProductById);
  const fetchClusters = useApiCall(Caas.getClusters);
  const fetchOsProjects = useApiCall(Provision.fetchOsProjectsV1);
  const { productId } = useParams();
  const userData = useSelector((state) => state.user);

  const [product, setProduct] = useState({});
  const [clusters, setClusters] = useState([]);
  const [osProjects, setOsprojects] = useState([]);
  const [activeClusters, setActiveClusters] = useState(false);
  const [activeOsProjects, setActiveOsProjects] = useState(false);

  const canViewContacts = rbacCheck(userData.roles, '/products:CONTACTS_VIEW');

  async function getProduct() {
    const [data] = await getProductCall(productId, true);
    if (data) setProduct(data);
  }

  async function getProductResources() {
    if (['caasonopenstack'].includes(product.type)) {
      const [data] = await fetchClusters({ juiceGroup: product.details.juiceGroup });
      if (data) {
        setClusters(data);
        setActiveClusters(data.some((cluster) => ['Pending', 'Approved', 'Provisioned'].includes(cluster.status)));
      }
    }
    if (['compute', 'evaluation'].includes(product.type)) {
      const [data] = await fetchOsProjects(product.details.juiceGroup);
      if (data) {
        setOsprojects(data);
        setActiveOsProjects(data.some((osProject) => ['active', 'deploying', 'deleting', 'deletionFailed'].includes(osProject.status)));
      }
    }
  }

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    if (product.details?.juiceGroup) {
      getProductResources();
    }
  }, [product]);


  return (
    <Grid
      className="content-with-header"
      container
      direction="column"
    >
      <Grid item alignSelf="flex-start">
        <Breadcrumbs key={product._id} separator=" > ">
          <Link to="/organizations">organizations</Link>
          <Link to={`/organizations/${product.organizationId}`}>{product.organization?.enterpriseName}</Link>
          <Link to={`/organizations/${product.organizationId}/projects`}>projects</Link>
          <Link to={`/projects/${product.project?._id}`}>{product.project?.name}</Link>
          <Link to={`/projects/${product.project?._id}`}>products</Link>
          <Typography>{product.name}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            rowGap={8}
            padding="2rem 0"
          >
            <Grid item>
              <Overview
                product={product}
                activeClusters={activeClusters}
                activeOsProjects={activeOsProjects}
                onChange={() => getProduct()}
              />
            </Grid>
            {canViewContacts && product.details?.juiceGroup && (
              <Grid item>
                <JuiceGroupContacts juiceGroup={product.details?.juiceGroup} />
              </Grid>
            )}
            {['compute', 'evaluation'].includes(product.type) && (
              <Grid item>
                <OsProjects osProjects={osProjects} product={product} />
              </Grid>
            )}
            {['caasonopenstack'].includes(product.type) && (
              <Grid item>
                <Clusters clusters={clusters} />
              </Grid>
            )}
            {['compute', 'evaluation', 'caasonopenstack'].includes(product.type) && (
              <Grid item>
                <Users organizationId={product.organizationId} projectId={product.projectId} />
              </Grid>
            )}
            {['compute', 'evaluation', 'caasonopenstack'].includes(product.type) && (
              <Grid item>
                <Section header="Cloud Report" divider>
                  <CloudReport juiceGroup={product.details?.juiceGroup} />
                </Section>
              </Grid>
            )}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
