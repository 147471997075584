import { useState, useEffect } from 'react';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useIntl } from 'react-intl';
import { scrollWithOffset } from '../../helpers/scrollWithOffset';
import MarkdownFromFile from '../../components/MarkdownFromFile/MarkdownFromFile';
import cookieBanner from '../../markdowns/cookieBanner.md';
import useAppContext from '../../hooks/useAppContext';

const CookieBanner = () => {
  const intl = useIntl();
  const location = useLocation();
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const hideCookieBanner = ['/data-privacy', '/about-us'].includes(location.pathname);
  const { cookiesAccepted, setCookiesAccepted } = useAppContext();

  useEffect(() => {
    if (cookiesAccepted || hideCookieBanner) {
      setShowCookieBanner(false);
    } else {
      setShowCookieBanner(true);
    }
  }, [location]);

  useEffect(() => {
    const checkCookieConsent = () => {
      if (window.Cookiebot) {
        window.Cookiebot.hide();
      }
    };
    window.addEventListener('load', checkCookieConsent);
    return () => window.removeEventListener('load', checkCookieConsent);
  }, []);

  useEffect(() => {
    const handleConsentReady = () => {
      setShowCookieBanner(false);
      setCookiesAccepted(true);
    };
    window.addEventListener('CookiebotOnConsentReady', handleConsentReady);
    return () => window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
  }, []);

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setConfirmChecked(checked);
    if (checked) {
      window.Cookiebot.submitCustomConsent(false, false, false, false);
    }
  };

  const renderLinks = () => (
    <Grid
      container
      justifyContent="space-between"
      gap={2}
    >
      <Grid item>
        <Link to="/data-privacy">
          <Typography>Privacy Policy</Typography>
        </Link>
      </Grid>
      <Grid item>
        <Link to="/about-us">
          <Typography>Imprint</Typography>
        </Link>
      </Grid>
      <Grid item>
        <HashLink
          to="/data-privacy#partner-list"
          scroll={scrollWithOffset}
        >
          <Typography>Partner List (2)</Typography>
        </HashLink>
      </Grid>
    </Grid>
  );

  return (
    showCookieBanner && (
      <Dialog
        open
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '60vh',
          },
        }}
      >
        <DialogTitle color="primary">
          {intl.formatMessage({ id: 'CookieBannerTitle' })}
        </DialogTitle>
        <DialogContent>
          <MarkdownFromFile file={cookieBanner} />
          <Checkbox
            checked={confirmChecked}
            onChange={handleCheckboxChange}
          />
          <Typography component="span">
            {intl.formatMessage({ id: 'CookieBannerCheckboxMessage1' })}
            <Link to="/data-privacy">
              <Typography component="span" display="inline">Privacy Policy</Typography>
            </Link>
            {intl.formatMessage({ id: 'CookieBannerCheckboxMessage2' })}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mb: 2, mx: 2 }}>
          {renderLinks()}
        </DialogActions>
      </Dialog>
    )
  );
};

export default CookieBanner;
