import { Container } from '@mui/material';

import MarkdownFromFile from '../../components/MarkdownFromFile/MarkdownFromFile';
import aboutUs from '../../markdowns/aboutUs.md';

const AboutUs = () => (
  <Container maxWidth="md">
    <MarkdownFromFile file={aboutUs} />
  </Container>
);

export default AboutUs;
