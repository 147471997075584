import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  MapContainer, TileLayer, CircleMarker, Tooltip,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Typography } from '@mui/material';

import MonitoringMs from '../../../../api/monitoring';
import useApiCall from '../../../../hooks/useApiCall';

import './GeoMap.css';

const countryLookup = {
  SK: [48.669, 19.699],
  CZ: [49.817, 15.472],
  RO: [45.943, 24.966],
  HU: [47.162, 19.503],
  AT: [47.516, 14.550],
  ME: [42.708, 19.374],
  PL: [51.919, 19.145],
  HR: [45.100, 15.200],
  MK: [41.608, 21.745],
  DE: [51.165, 10.451],
};


const GeoMap = ({
  panelInfo, dateRange, mapZoom, styles,
}) => {
  const { dashboardId } = useParams();
  const [values, setValues] = useState();
  const getPanelDataApiCall = useApiCall(MonitoringMs.getPanel);

  const getPanel = async (targets) => {
    const [data] = await getPanelDataApiCall(targets, dashboardId);
    if (data) {
      const { refId } = panelInfo.targets.find((target) => !target.hide);
      const fieldLookup = panelInfo.transformations.find((t) => t.id === 'organize')?.options.renameByName;
      const panelData = {
        values: data.results[refId]?.frames[0].data.values,
        fieldNames: data.results[refId]?.frames[0].schema.fields.map(
          (field) => fieldLookup[field.name] || field.name,
        ),
      };

      if (panelData) {
        const markers = panelData.values[0].map((country, index) => ({
          marker: {
            country,
            position: countryLookup[country] || [],
            value: panelData.values[1][index],
          },
          schema: [
            {
              fieldName: panelData.fieldNames[0],
              value: country,
            },
            {
              fieldName: panelData.fieldNames[1],
              value: panelData.values[1][index],
            },
          ],
        }));
        setValues(markers);
      }
    }
  };

  useEffect(() => {
    const toDate = new Date(dateRange.to).getTime();
    const fromDate = new Date(dateRange.from).getTime();
    const targets = panelInfo.targets.filter((target) => !target.hide);
    if (targets) {
      getPanel({ queries: targets, to: toDate.toString(), from: fromDate.toString() });
    }
  }, []);

  return values ? (
    <MapContainer
      center={[48.669, 19.699]}
      minZoom={3}
      zoom={mapZoom}
      attributionControl={false}
      style={{
        height: styles.height ? styles.height : '100%',
        width: styles.width ? styles.width : '100%',
      }}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
      />
      {values.map((value) => (
        <div key={value.marker.country}>
          <CircleMarker
            center={value.marker.position}
            radius={value.marker.value / 10 + 10}
            color="#E20074"
            weight={1}
            fillOpacity={0.2}
          >
            <Tooltip direction="auto" className="dark-tooltip">
              <table>
                <thead>
                  <tr>
                    <th><Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>{panelInfo.title}</Typography></th>
                  </tr>
                </thead>
                <tbody>
                  {value.schema.map((field) => (
                    <tr key={`${field.fieldName}+${value.marker.country}`}>
                      <td><Typography sx={{ fontWeight: 'bold' }}>{`${field.fieldName}:`}</Typography></td>
                      <td align="right">
                        <Typography>{field.value || 'N/A'}</Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tooltip>
          </CircleMarker>

          <CircleMarker
            center={value.marker.position}
            radius={0}
            opacity={0}
          >
            <Tooltip direction="center" permanent className="transparent-tooltip">
              <Typography align="center" color="primary">{value.marker.value}</Typography>
            </Tooltip>
          </CircleMarker>
        </div>

      ))}
    </MapContainer>
  ) : null;
};


GeoMap.propTypes = {
  panelInfo: PropTypes.shape({
    title: PropTypes.string,
    targets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    repeat: PropTypes.string,
    transformations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    gridPos: PropTypes.shape({
      h: PropTypes.number,
      w: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  mapZoom: PropTypes.number.isRequired,
  styles: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

GeoMap.defaultProps = {
  styles: {},
};

export default GeoMap;
