import { Container } from '@mui/material';

import MarkdownFromFile from '../../../components/MarkdownFromFile/MarkdownFromFile';
import safecsarfSpecification from '../../../markdowns/Safescarf/specification.md';

const SafescarfSpecification = () => (
  <Container maxWidth="lg">
    <MarkdownFromFile file={safecsarfSpecification} />
  </Container>
);

export default SafescarfSpecification;
