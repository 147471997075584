import { Container } from '@mui/material';


import MarkdownFromFile from '../../components/MarkdownFromFile/MarkdownFromFile';
import contactUs from '../../markdowns/contactUs.md';

const ContactUs = () => (
  <Container maxWidth="md">
    <MarkdownFromFile file={contactUs} />
  </Container>
);

export default ContactUs;
