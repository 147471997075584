import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '../../../../../components/Table';

const Clusters = ({ clusters }) => {
  const navigate = useNavigate();


  const ClustersTableRows = clusters?.map((cluster) => ({
    key: cluster.name,
    onRowClick: () => navigate(`/clusters/${cluster.name}`),
    data: [
      { field: 'name', value: cluster.name },
      { field: 'cloud', value: cluster.cloud },
      { field: 'status', value: cluster.status.operationStatus },
    ],
  }));


  return (
    <Table
      header="Clusters"
      fields={[
        { id: 'name', title: 'Name' },
        { id: 'cloud', title: 'Cloud' },
        { id: 'status', title: 'Status' },
      ]}
      rows={ClustersTableRows}
    />
  );
};

Clusters.propTypes = {
  clusters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    cloud: PropTypes.string,
    status: PropTypes.shape({
      operationStatus: PropTypes.string,
    }),
  })),
};

Clusters.defaultProps = {
  clusters: [],
};

export default Clusters;
